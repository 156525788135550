const DisplayEmpruntModal = () => import('@/components/modals/emprunt/DisplayEmpruntModal');
const EditEmpruntModal    = () => import('@/components/modals/emprunt/EditEmpruntModal');
const ConfirmModal        = () => import('@/components/modals/ConfirmModal');

import {apiPath}    from '@/util/http';
import {rowVariant} from '@/util/emprunt';

export default {
    methods: {
        addRowVariant(emprunts) {
            return emprunts.map(emprunt => ({
                _rowVariant: rowVariant(emprunt),
                ...emprunt
            }))
        },
        displayEmpruntModal(emprunt) {
            this.$store.dispatch('modal/create', {
                component: DisplayEmpruntModal,
                props: {emprunt: emprunt, callback: this.loadData}
            });
        },
        editEmpruntModal(emprunt) {
            this.$store.dispatch('modal/create', {
                component: EditEmpruntModal,
                props: {emprunt: emprunt, callback: this.loadData}
            });
        },
        deleteEmprunt(emprunt) {
            this.$store.dispatch('modal/create', {
                component: ConfirmModal,
                props: {
                    title: 'Supprimer cet emprunt ?',
                    callback: () => this.axios
                        .delete(apiPath('delete_emprunt', {emprunt: emprunt.id}))
                        .then(() => {
                            this.$toaster.success('Emprunt supprimé avec succès');
                            this.loadData();
                        })
                        .catch(() => this.$toaster.error("Impossible de supprimer l'emprunt"))
                }
            });
        }
    }
};