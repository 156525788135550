export const cautionTotale = emprunt => emprunt.jdrs.reduce((sum, {caution}) => sum + caution, 0) +
                                        emprunt.jeux.reduce((sum, {caution}) => sum + caution, 0) +
                                        emprunt.livres.reduce((sum, {caution}) => sum + caution, 0);

export const STATUT_EMPRUNT_CREE  = 0;
export const STATUT_OBJETS_PARTIS = 1;
export const STATUT_OBJETS_RENDUS = 2;

export const STATUT_OPTIONS = [
    {value: STATUT_EMPRUNT_CREE, text: 'Emprunt créé'},
    {value: STATUT_OBJETS_PARTIS, text: 'Objets partis'},
    {value: STATUT_OBJETS_RENDUS, text: 'Objets rendus'}
];

export const rowVariant = emprunt => {
    if (!emprunt.valide) // Either not valid or not decided yet
        return null;

    if (emprunt.statut === STATUT_OBJETS_RENDUS) // All good
        return 'success';

    if (emprunt.statut < STATUT_OBJETS_RENDUS && emprunt.dateFin.isBefore()) // Too late
        return 'danger';

    return null;
};

export default {cautionTotale, rowVariant, STATUT_OPTIONS, STATUT_EMPRUNT_CREE, STATUT_OBJETS_PARTIS, STATUT_OBJETS_RENDUS};